import "./App.css";
import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/Loading/Loading.jsx";

const Home = lazy(() => import("./home.jsx"));
const Gallery = lazy(() => import("./gallery.jsx"));
const About = lazy(() => import("./about.jsx"));
const Images = lazy(() => import("./images.jsx"));
const Videos = lazy(() => import("./videos.jsx"));
const Contact = lazy(() => import("./contact"));

const App = () => (
  <Router>
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* Page not found */}
        <Route path="*" element={<div>Page not found</div>} />

        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="gallery/:id" element={<Images />} />
        <Route path="/about_us" element={<About />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/contact" element={<Contact />} />
        
      </Routes>
    </Suspense>
  </Router>
);

export default App;
